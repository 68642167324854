document.addEventListener('DOMContentLoaded', () => {
    const sliderElement = document.querySelector('[data-slider]')
    const navPrev = sliderElement.parentElement.querySelector('[data-slider-nav-prev]')
    const navNext = sliderElement.parentElement.querySelector('[data-slider-nav-next]')

    const swiper = new Swiper(sliderElement, {
        speed: 600,        
        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1000: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
        navigation: {
          prevEl: navPrev,
          nextEl: navNext,
        //   disabledClass: 'is-disabled'
        },        
    })
})